@charset "UTF-8";

/*
Theme Name: artofmujtaba PROJECT
Theme URI: 
Author: 
Author URI: 
Description: artofmujtaba PROJECT - artofmujtaba PROJECT, .... Template
Version: 1.0.0
*/

// Saira+Stencil+One
@import url('https://fonts.googleapis.com/css2?family=Saira+Stencil+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rancho&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

:root {
    --body_font-size: 16px;
    --body_color: #1f1f1f;
    --body_color1: #303030;
    --line-height30: 1.8;
    --main_color: #56b887;
    --main_background_color_gradiant: linear-gradient(278.99deg, #65b15b 57.37%, #fe761e 113.65%);
    --main_color-gradiant: -webkit-linear-gradient(#fdf0f0, #65b15b);
    --body_font: 'Poppins';
    --heading_fontLobster: "Lobster", sans-serif;
    --logo_font: 'Saira Stencil One', sans-serif;
    --Overpass_font: 'Overpass', sans-serif;
    --paragraph_color: #a3a3a3;
    --black_color: #000000;
    --white_color: #fff;
    --red_color: #dc2626;
    --waluppcolor: #ff8189;
    --arinstrumedcolor: #a5cd39;
    --alphaautocolor: #f36034;
    --numerocolor: #c29456;
    --alloywheelscolor: #e30a15;
    --meditrocolor: #fe970e;
    --smmprojectcolor: #b33cdb;
    --bitvortexcolor: #00c4f4;
    --listandsellcolor: #151a7b;
    --fslegalscolor: #DE0101;
    --card-shadow: 11px 10px 38px hsla(0, 0%, 0%, 38%);
    // gradiant light
    --bluelight_color: linear-gradient(90deg, #70BBFF 42.31%, #82C3FF 119.22%, rgba(112, 187, 255, 0) 119.23%);
}

.heading_fontLobster {
    font-family: var(--heading_fontLobster);
}

.body_bg {
    background-color: var(--body_color) !important;
}

.body_text {
    color: var(--body_color) !important;
}

.paragraph_bg {
    background-color: var(--paragraph_color) !important;
}

.paragraph_text {
    color: var(--paragraph_color) !important;
}

.white_bg {
    background-color: var(--white_color) !important;
}

.white_text {
    color: var(--white_color) !important;
}

.black_bg {
    background-color: var(--black_color) !important;
}

.black_text {
    color: var(--black_color) !important;
}

.main_bg {
    background-color: var(--main_color) !important;
}

.main_text {
    color: var(--main_color) !important;
}

.body_color_bg {
    background-color: var(--body_color1) !important;
}

.body_color_text {
    color: var(--body_color1) !important;
}

html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    overflow-x: hidden;
    // font-family: var(--body_font);
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    -moz-osx-font-smoothing: grayscale;
    /* Firefox */
    -webkit-font-smoothing: antialiased;
    /* WebKit  */
}

body {
    margin: 0;
    color: var(--paragraph_color);
    font-family: var(--body_font);
    line-height: var(--line-height30);
    font-size: var(--body_font-size);
    background-color: var(--body_color);
    min-height: 100vh;
    // letter-spacing: 1px;
    padding-right: 0 !important;
}

.line_hieght_normal {
    line-height: normal !important;
}

@media(min-width:1300px) {
    .mx-w-50 {
        max-width: 50%;
    }

    .mx-w-75 {
        max-width: 60%;
    }
}

.max-w-md {
    max-width: 32rem;
}

::selection {
    background: transparent;
    color: var(--button_color);
}

.minh-auto {
    min-height: auto !important;
}

.overflow_inherit {
    overflow: inherit !important;
}

h1 {
    font-size: 86px;
    line-height: 1.2333333333;
}

h2 {
    font-size: 42px;
    line-height: 1.3380952381;
}

h3 {
    font-size: 32px;
    line-height: 1.3833333333;
}

h4 {
    font-size: 28px;
    line-height: 1.3380952381;
}

h5 {
    font-size: 24px;
    line-height: 1.3380952381;
}

h6 {
    font-size: 18px;
    line-height: 1.2380952381;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--black_color);
    font-family: var(--body_font);
    line-height: normal;
    font-weight: normal;
}

.text-gradiant {
    background: var(--main_background_color_gradiant);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    --text-fill-color: transparent;
}

p {
    color: var(--paragraph_color);
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    letter-spacing: 1px;
    hyphens: auto;
    font-family: var(--body_font);
    margin-bottom: 10px;
    word-break: break-word;
    line-height: 30px;
    font-size: 16px;
    font-weight: 200;
}

a {
    color: inherit;
    text-decoration: none;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    // letter-spacing: 0.85px;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
    color: inherit;
    font-family: var(--body_font);
}

a:hover {
    color: var(--main_color);
}

pre {
    word-break: break-word;
}

img {
    max-width: 100%;
    height: auto;
}

ol {
    counter-reset: counter;
    padding-left: 0;
}

ol li {
    list-style: none;
    margin-bottom: 1rem;
    letter-spacing: 1px;
    font-family: var(--body_font);
}

// ol li:before {
//     counter-increment: counter;
//     content: counter(counter);
//     font-weight: 500;
//     margin-right: 10px;
// }
button:hover,
button:active,
button:focus {
    outline: 0;
}

button:focus:not(:focus-visible) {
    box-shadow: none;
}

.btn {
    font-family: var(--body_font);

    &:focus {
        box-shadow: none;
    }
}


/*input and button type focus outline disable*/

input[type=text]:focus,
input[type=email]:focus,
input[type=url]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type=number]:focus,
textarea:focus,
input[type=button]:focus,
input[type=reset]:focus,
input[type=submit]:focus,
select:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #ddd;
}

::selection {
    color: var(--main_color);
    background: transparent;
}

// main {
//     margin-top: 120px;
// }
// @media(max-width:767px){
//     main {
//         margin-top: 80px;   
//     }
// }

/*----------------hire-----------------*/
.hire {
    display: inline-block;
    text-decoration: none;
    background-color: var(--main_color);
    color: var(--white_color) !important;
    border: none;
    outline: none;
    box-shadow: 0 1em 3em -1.25em rgba(0, 0, 0, 0.3);
    padding: 0.45em 2em;
    text-transform: uppercase;
    border-radius: 0px 100px 100px 90px;
    font-weight: 200;
    font-size: 18px;
    letter-spacing: 1px;
    position: relative;
    margin: 1em 0em 0;
    overflow: hidden;
}

.shine {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform: skew(30deg) translateX(-120%);
    background-color: rgba(255, 255, 255, 0.3);
    -webkit-animation: shine 2s ease infinite;
    animation: shine 2s ease infinite;
}

@-webkit-keyframes shine {
    0% {
        transform: skew(30deg) translateX(-120%);
    }

    30% {
        transform: skew(30deg) translateX(120%);
    }

    100% {
        transform: skew(30deg) translateX(120%);
    }
}

@keyframes shine {
    0% {
        transform: skew(30deg) translateX(-120%);
    }

    30% {
        transform: skew(30deg) translateX(120%);
    }

    100% {
        transform: skew(30deg) translateX(120%);
    }
}

/*----------------header-----------------*/

.header {
    position: relative;
    z-index: 4;
    // background-color: #303030;
    transition: top 0.6s ease-in-out;
    width: 100%;

    // background-color: rgba(31, 31, 31, 0.8);
    // border-bottom: solid 1px rgba(84, 186, 135, 0.3);
    &.nav-down {
        top: -100px;
    }

    .navbar {
        flex-wrap: inherit;

        // height: 100px;
        .navbar-brand {
            position: relative;
            z-index: 1;

            img {
                width: 260px;
            }

            h1 {
                font-size: 2rem;
                text-transform: uppercase;
                margin: 0;
                font-family: var(--logo_font);
                color: var(--white_color);

                span {
                    transition: all .6s;
                }

                &::after {
                    content: "";
                    display: inline-block;
                    height: 0.17em;
                    width: 0.2em;
                    background-color: var(--main_color);
                }

                &:hover,
                &:focus {
                    span {
                        color: var(--main_color);
                    }
                }
            }
        }

        // 
        .navbar-nav {
            flex-direction: row;

            .nav-item {
                margin-right: 1.1rem;

                .nav-link {
                    font-weight: bold;
                    padding: 0.4rem 0.4rem !important;
                    font-family: var(--body_font);
                    font-size: 1rem;
                    position: relative;
                    color: var(--white_color);

                    &:hover,
                    &:focus {
                        &::before {
                            width: 100%;
                        }
                    }

                    &::after {
                        content: "";
                        display: inline-block;
                        height: 0.2em;
                        width: 0.2em;
                        margin-left: 3px;
                        background-color: var(--white_color);
                        border-radius: 50%;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                        height: 0.15rem;
                        width: 0;
                        left: 0;
                        background-color: var(--main_color);
                        -webkit-transition: all 0.6s ease-in-out;
                        transition: all 0.6s ease-in-out;
                    }

                    &.phone {
                        width: 40px;
                        height: 40px;
                        min-height: 40px;
                        max-width: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: var(--body_color1);
                        border-radius: 100px;
                        border: 1px solid transparent;
                        transition: .6s;

                        img {
                            width: 18px;
                        }

                        &:hover,
                        &:focus {
                            background: var(--main_color);

                            &::before {
                                display: none;
                            }

                            border-color:var(--main_color);
                        }

                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    &.nav-up {
        transition: top 0.6s ease-in-out;

        .navbar {
            height: 70px;
            // .navbar-brand{
            //   h1{
            //     &::after{
            //       background-color: var(--body_color);
            //     }
            //   }
            // }
            // .navbar-nav{
            //   .nav-item{
            //     .nav-link{
            //       &:hover,&:focus{
            //         color: var(--body_color);
            //         &::before{
            //           background-color: var(--body_color);
            //         }
            //       }
            //       &.phone{
            //         &:hover,&:focus{
            //           background-color: var(--body_color);
            //         }
            //       }
            //     }
            //   }
            // }
        }
    }
}

.logoBan {
    height: 200px;
    border-radius: 20px;
}

@media(max-width:575px) {
    .header .navbar {
        .navbar-brand {
            img {
                width: 180px;
            }
        }
    }

    .logoBan {
        height: 100px;
        border-radius: 5px;
    }
}

/*----------------rfm-marquee-----------------*/
.rfm-initial-child-container {
    gap: 10px !important;
}

.rfm-marquee {
    gap: 10px !important;
}

.image_wrapper {
    margin: 0px;
    background-color: var(--body_color);
    border-radius: 15px;
    border: solid 3px var(--body_color1);
    width: 100%;
    padding: 10px;
    overflow: hidden;
}


.b-rand_logo {
    img {
        transition: all .6s ease;
        cursor: pointer;
        filter: grayscale(1);
    }

    &:hover {
        img {
            filter: grayscale(0);
        }
    }
}

/*----------------footer-----------------*/

footer.footer {
    background-color: var(--body_color1);
    font-size: 0.95rem;
    line-height: 1.5rem;
    padding-top: 15px;
    padding-bottom: 15px;
    -webkit-box-shadow: var(--card-shadow);
    box-shadow: var(--card-shadow);

    .navbar-brand {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;

        h1 {
            font-size: 1.50rem;
            text-transform: uppercase;
            margin: 0;
            font-family: var(--logo_font);
            color: var(--white_color);

            span {
                transition: all .6s;
            }

            &::after {
                content: "";
                display: inline-block;
                height: 0.17em;
                width: 0.2em;
                background-color: var(--main_color);
            }

            &:hover,
            &:focus {
                span {
                    color: var(--main_color);
                }
            }
        }
    }
}


/*----------------footer-----------------*/

.contact {
    .contact-form {
        background: var(--body_color1);
        padding: 25px;
        margin: auto;
        border-radius: 0.63rem;
        -webkit-box-shadow: var(--card-shadow);
        box-shadow: var(--card-shadow);
        visibility: visible;

        .form-label {
            color: var(--white_color);
            font-size: 12px;
            display: block;
            font-weight: bold;
        }

        .input-group {
            border: 1px solid transparent;

            .form-control {
                height: 40px;
                background-color: var(--body_color);
                padding-left: 30px;
                padding-right: 14px;
                font-size: 14px;
                border: 1px solid;
                outline: 0;
                border-radius: 8px;
                overflow: hidden;
                -webkit-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;

                &::-webkit-input-placeholder {
                    color: var(--paragraph_color);
                    opacity: 1;
                }

                &::-moz-placeholder {
                    color: var(--paragraph_color);
                    opacity: 1;
                }

                &:-ms-input-placeholder {
                    color: var(--paragraph_color);
                    opacity: 1;
                }

                &::-ms-input-placeholder {
                    color: var(--paragraph_color);
                    opacity: 1;
                }

                &::placeholder {
                    color: var(--paragraph_color);
                    opacity: 1;
                }

                &:focus {
                    outline: none;
                    box-shadow: none;
                    color: var(--main_color);
                    opacity: 1;
                    background-color: var(--body_color);
                    transition: all .6s;
                    border-color: var(--main_color);

                    &::placeholder {
                        color: var(--main_color);
                        opacity: 1;
                    }
                }
            }

            .input-group-text {
                background-color: transparent;
                border: none;
            }

            .prefix-icon {
                position: absolute;
                width: 30px;
                min-width: 30px;
                border-radius: 8px;
                text-align: center;
                left: 0;
                height: 100%;
                z-index: 4;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 18px;
                }
            }
        }
    }

    // contact-info
    .contact-info {
        margin-top: 60px;

        .nav-item {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            margin-bottom: 20px;
            -webkit-box-align: start;
            -ms-flex-align: start;
            align-items: flex-start;
            font-size: 0.90rem;
        }
    }

    // social_bottom
    .social_bottom {
        display: inline-block;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        font-size: 18px;
        text-transform: uppercase;
        -webkit-transform-origin: left top;
        transform-origin: left top;

        .nav {
            .nav-item {
                margin-right: 15px;

                a.has-tooltip {
                    position: relative;
                    width: 35px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100px;
                    background: var(--white_color);

                    &:hover,
                    &:focus {
                        background-color: var(--main_color);

                        .custom-tooltip {
                            visibility: visible;
                            opacity: 1;
                            top: 0;
                        }
                    }

                    .custom-tooltip {
                        position: absolute;
                        z-index: 1;
                        min-width: -webkit-max-content;
                        min-width: -moz-max-content;
                        min-width: max-content;
                        background-color: var(--main_color);
                        color: var(--white_color);
                        font-size: 0.8rem;
                        left: 50%;
                        top: -30px;
                        -webkit-transform: translate(-50%, -150%);
                        transform: translate(-50%, -150%);
                        font-weight: 500;
                        padding: 0.4rem 1rem;
                        border-radius: 0.63rem;
                        visibility: hidden;
                        opacity: 0;
                        pointer-events: none;
                        -webkit-filter: var(--drop-shadow);
                        filter: var(--drop-shadow);
                        -webkit-transition: all 0.3s ease;
                        transition: all 0.3s ease;

                        &::after {
                            left: 50%;
                            -webkit-transform: translate(-50%, 100%);
                            transform: translate(-50%, 100%);
                            -webkit-clip-path: polygon(50% 100%, 0 0, 100% 0);
                            clip-path: polygon(50% 100%, 0 0, 100% 0);
                            top: 50%;
                            height: 13px;
                            width: 18px;
                            content: "";
                            position: absolute;
                            background-color: var(--main_color);
                        }
                    }
                }
            }
        }
    }
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

// media
@media (min-width: 1199px) {
    .contact {
        .contact-form {
            max-width: 500px;
        }
    }
}


/*----------------section-----------------*/

.section:not(.page) {
    padding-top: 40px;
    padding-bottom: 40px;
}

.section {
    outline: none;
}

@media(max-width:1199px) {
    .section:not(.page) {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

/*----------------statistics-items-----------------*/

.statistics-items {
    a {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 100%;
        position: relative;
        background-color: var(--body_color);
        padding: 30px;
        border-radius: 0 0 0 70px;

        &:hover,
        &:focus {
            -webkit-box-shadow: 0px 0px 30px hsla(0, 0%, 0%, 30%);
            box-shadow: 0px 0px 30px hsla(0, 0%, 0%, 30%);
            // margin-top: -15px;
            transform: scale(0.95);

            .icon {
                background: var(--main_color);
                // img{
                //   -webkit-filter: invert(1);
                //   filter: invert(1);
                // }
            }

            .arrow_circle {
                border-color: var(--main_color);
            }
        }

        .icon {
            height: 64px;
            width: 64px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            transition: .6s;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            border-radius: 50%;
            background: var(--white_color);

            img {
                width: 40px;
            }
        }

        // text_content
        .text_content {
            width: calc(100% - 64px - 60px);
            text-align: left;
            margin-left: 30px;
            margin-right: 30px;
            color: var(--white_color);
            text-transform: uppercase;
            span{
                text-transform: uppercase;
                font-weight: 200;
            }

            h3 {
                color: var(--main_color);
                font-size: 2.3rem;
                font-weight: 700;
                margin-bottom: 5px;
            }
        }

        // arrow_circle
        .arrow_circle {
            position: absolute;
            right: 15px;
            width: 50px;
            height: 50px;
            border-radius: 100px;
            border: solid 2px var(--body_color);
            transition: .6s;
            background: var(--body_color);
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 30px;
            }
        }
    }
}


/*----------------cursor-----------------*/

.hero-section {
    background-image: url(../images/Backgrounds-e.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    .hero_content {

        // padding-top: 120px;
        h1 {
            color: var(--white_color);
            font-weight: 100;
            font-size: 5rem;
            line-height: normal;
            text-transform: uppercase;
            span{
                text-transform: none;
            }
        }

        h3 {
            color: var(--white_color);
            font-weight: 200;
            font-size: 1.2rem;
            font-style: normal;
            text-transform: uppercase;
            line-height: normal;
        }

        .index-module_type__E-SaG {
            font-weight: 400;
            color: var(--main_color);
            font-family: "Lobster", sans-serif;
        }

        .hello-btn {
            border: solid 2px var(--white_color);
            border-radius: 100px;
            max-width: fit-content;
            min-width: fit-content;
            color: var(--white_color);
            font-size: 14px;
            height: 40px;
            padding-left: 30px;
            padding-right: 30px;
            display: inline;
            font-weight: 200;
            transition: all .6s;

            &:hover {
                background-color: var(--main_color);
            }
        }
    }

    .hero-img {
        .layer {
            position: absolute;
            width: 100%;

            img {
                display: block;
                // -webkit-filter: drop-shadow(2px 4px 6px black);
                // filter: drop-shadow(0px 0px 4px #226c18);
            }

            // &:last-of-type {
            //     // height: 50%;
            //     overflow: hidden;
            // }
            // &:first-of-type {
            //     height: 100%;
            //     -webkit-mask-image: url(../images/splash.png);
            //     mask-image: url(../images/splash.png);
            //     -webkit-mask-repeat: no-repeat;
            //     mask-repeat: no-repeat;
            //     -webkit-mask-position: center;
            //     mask-position: center;
            //     -webkit-mask-size: contain;
            //     mask-size: contain;
            //     &::before {
            //         content: "";
            //         position: absolute;
            //     }
            // }
            &:last-of-type {
                overflow: hidden;
            }

            &:first-of-type {
                height: 40%;
                -webkit-mask-image: url(../images/circle.svg);
                mask-image: url(../images/circle.svg);
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                -webkit-mask-position: center;
                mask-position: center;
                -webkit-mask-size: contain;
                mask-size: contain;

                &::before {
                    content: "";
                    position: absolute;
                }
            }
        }
    }

    // .hero-text
    .hero-text {
        text-align: center;

        h1 {
            margin-bottom: 30px;
            color: var(--white_color);
            font-weight: bold;
        }

        .btn {
            text-transform: uppercase;
            font-weight: bold;
            padding: 0.5rem 1.5rem;
            color: var(--white_color);
            transition: .6s;

            &:hover {
                background: var(--white_color);
                color: var(--black_color);
            }
        }
    }

    // scroll-down
    .scroll-down {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: absolute;
        bottom: 20px;
        font-size: 18px;
        text-transform: uppercase;
        right: 30px;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
        -webkit-transform-origin: right top;
        transform-origin: right top;

        a {

            &:hover,
            &:focus {
                color: var(--white_color);
            }
        }

        &::after {
            content: "";
            display: inline-block;
            height: 2px;
            width: 30px;
            background-color: var(--paragraph_color);
            -webkit-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
        }
    }

    // 
    .social_2 {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 18px;
        margin: 0 auto;
        width: 100%;
        max-width: 290px;
        min-width: 290px;
        text-transform: uppercase;

        .nav {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;

            .nav-item {
                display: flex;

                a.has-tooltip {
                    position: relative;
                    width: 25px;
                    height: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100px;

                    &:hover,
                    &:focus {
                        background-color: rgba(86, 184, 135, 0.8);

                        .custom-tooltip {
                            visibility: visible;
                            opacity: 1;
                            left: calc(100% + 2rem);
                        }
                    }

                    .custom-tooltip {
                        position: absolute;
                        z-index: 1;
                        display: none;
                        min-width: -webkit-max-content;
                        min-width: -moz-max-content;
                        min-width: max-content;
                        background-color: var(--main_color);
                        color: var(--white_color);
                        font-size: 0.8rem;
                        left: calc(100% + 4rem);
                        top: 50%;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                        font-weight: 500;
                        padding: 0.4rem 1rem;
                        border-radius: 0.63rem;
                        visibility: hidden;
                        opacity: 0;
                        pointer-events: none;
                        -webkit-filter: var(--drop-shadow);
                        filter: var(--drop-shadow);
                        -webkit-transition: all 0.3s ease;
                        transition: all 0.3s ease;

                        &::after {
                            left: 1px;
                            -webkit-transform: translate(-100%, -50%);
                            transform: translate(-100%, -50%);
                            -webkit-clip-path: polygon(0 50%, 100% 0, 100% 100%);
                            clip-path: polygon(0 50%, 100% 0, 100% 100%);
                            top: 50%;
                            height: 15px;
                            width: 10px;
                            content: "";
                            position: absolute;
                            background-color: var(--main_color);
                        }
                    }
                }
            }
        }
    }
}

.social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: fixed;
    bottom: 20px;
    font-size: 18px;
    z-index: 2;
    text-transform: uppercase;
    left: 30px;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left top;
    transform-origin: left top;

    // &::after{
    //   content: "";
    //   display: inline-block;
    //   height: 2px;
    //   width: 30px;
    //   background-color: var(--paragraph_color);
    //   -webkit-transition: all 0.3s ease-in-out;
    //   transition: all 0.3s ease-in-out;
    // }
    .nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);

        .nav-item {
            -webkit-transform: rotateX(0deg) rotateY(180deg) rotateZ(90deg);
            transform: rotateX(0deg) rotateY(180deg) rotateZ(90deg);
            margin-right: 20px;

            a.has-tooltip {
                position: relative;
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100px;

                &:hover,
                &:focus {
                    background-color: rgba(86, 184, 135, 0.8);

                    .custom-tooltip {
                        visibility: visible;
                        opacity: 1;
                        left: calc(100% + 2rem);
                    }
                }

                .custom-tooltip {
                    position: absolute;
                    z-index: 1;
                    min-width: -webkit-max-content;
                    min-width: -moz-max-content;
                    min-width: max-content;
                    background-color: var(--main_color);
                    color: var(--white_color);
                    font-size: 0.8rem;
                    left: calc(100% + 4rem);
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
                    font-weight: 500;
                    padding: 0.4rem 1rem;
                    border-radius: 0.63rem;
                    visibility: hidden;
                    opacity: 0;
                    pointer-events: none;
                    -webkit-filter: var(--drop-shadow);
                    filter: var(--drop-shadow);
                    -webkit-transition: all 0.3s ease;
                    transition: all 0.3s ease;

                    &::after {
                        left: 1px;
                        -webkit-transform: translate(-100%, -50%);
                        transform: translate(-100%, -50%);
                        -webkit-clip-path: polygon(0 50%, 100% 0, 100% 100%);
                        clip-path: polygon(0 50%, 100% 0, 100% 100%);
                        top: 50%;
                        height: 15px;
                        width: 10px;
                        content: "";
                        position: absolute;
                        background-color: var(--main_color);
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .hero-section {
        position: relative;
        height: calc(80vh - 0vh);

        .hero-img {
            .layer {
                bottom: 0;
                left: 0;

                // -webkit-transition: -webkit-transform 3s ease;
                // transition: -webkit-transform 3s ease;
                // transition: transform 3s ease;
                // transition: transform 3s ease, -webkit-transform 3s ease;
                img {
                    position: relative;
                    z-index: 2;
                    height: calc(100vh - 70px);
                    max-width: 100%;
                    margin: auto;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                }

                &:first-of-type {
                    &::before {
                        z-index: 1;
                        bottom: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        background: -webkit-gradient(linear, left top, right top, from(var(--main_color)), to(var(--main_color)));
                        background: linear-gradient(to right, var(--main_color) 0%, var(--main_color) 100%);
                    }
                }
            }
        }

        // .hero-text
        .hero-text {
            position: absolute;
            z-index: 3;
            bottom: 0;
            width: fit-content;
            padding: 0 30px;
            text-shadow: 0px 0px 20px var(--black_color);
            left: 50%;
            transform: translateX(-50%);

            h1 {
                color: var(--white_color) !important;
                font-size: 2rem;
                font-weight: bold;
            }

            .btn {
                color: var(--white_color);
                border-color: var(--white_color);
            }
        }
    }
}

@media (max-width: 991px) {
    .hero-section {
        min-height: calc(60vh - 0vh);
        display: grid;
        place-content: center;

        // padding-top: 80px;
        // padding-bottom: 80px;
        .hero_content {
            h1 {
                font-size: 5rem;
                line-height: 6rem;
                // span{
                //     font-size: 2rem;
                // }
            }
        }

        .hero-img {
            // position: relative;
            // height: 600px;
            // max-height: 400px;
            width: 100vw;
            // max-width: 400px;
            margin: auto;

            .layer {
                left: 50%;

                img {
                    height: 100%;
                    width: 100%;
                    -o-object-fit: contain;
                    object-fit: contain;
                }

                &:first-of-type {
                    -webkit-transform: translate(-50%, 0%);
                    transform: translate(-50%, 0%);
                    bottom: 3%;
                    height: 35%;

                    &::before {
                        bottom: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        background-color: var(--main_color);
                        // border-radius: 50%;
                        // transform: translate(53px);
                    }
                }

                &:last-of-type {
                    -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);
                    top: 0;

                    &::before {
                        left: 50%;
                        -webkit-transform: translateX(-50%);
                        transform: translateX(-50%);
                    }

                    img {
                        -webkit-transform: translateX(-0%);
                        transform: translateX(-0%);
                        height: 200%;
                        width: 200%;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .hero-section {

        // min-height: 100%;
        .hero_content {
            h1 {
                font-size: 4rem;
                // span{
                //     font-size: 2rem;
                // }
            }
        }

        .hero-img {
            .layer {
                &:first-of-type {
                    right: 0;
                    left: 0;
                    margin: 0 auto;
                    bottom: 0;
                    width: 100%;
                    text-align: center;
                    transform: translateX(-0%) !important;
                }
            }
        }
    }
}

@media (max-width: 575px) {
    .hero-section {
        .hero_content {
            h1 {
                font-size: 3rem;
                line-height: 5rem;
                // span{
                //     font-size: 1rem;
                // }
            }
        }


        .hero-text {
            h1 {
                font-size: 22px;
            }
        }
    }
}

// @media (max-width: 480px) {
//     .hero-section {
//         .social{
//             bottom: 120px;
//         }
//     }
// }

/*----------------cursor-----------------*/

.circle-cursor {
    position: fixed;
    z-index: 99999999;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    mix-blend-mode: difference;
    pointer-events: none;
    visibility: hidden;

    &.circle-cursor-outer {
        height: 30px;
        width: 30px;
        border: 1px solid var(--main_color);
        opacity: 0.5;
        -webkit-transition: all 0.08s ease-out;
        transition: all 0.08s ease-out;
    }

    &.circle-cursor-inner {
        height: 6px;
        width: 6px;
        background-color: var(--main_color);
        -webkit-transition: top 0.02s ease-out, left 0.02s ease-out, height 0.2s ease-in-out, width 0.2s ease-in-out, opacity 0.2s ease-in-out;
        transition: top 0.02s ease-out, left 0.02s ease-out, height 0.2s ease-in-out, width 0.2s ease-in-out, opacity 0.2s ease-in-out;

        &.cursor-link-hover {
            height: 20px;
            width: 20px;
            opacity: 0.5;
        }

        &.cursor-click-effect {
            height: 13px;
            width: 13px;
        }
    }
}


/*----------------back-to-top-----------------*/

.about-img {
    position: relative;
    visibility: visible;
}

@media (min-width: 992px) {
    .about-img {
        height: 500px;
    }
}

@media (max-width: 991px) {
    .about-img {
        height: 400px;
    }
}

.about-img {
    .layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        img {
            position: relative;
            z-index: 2;
            display: block;
            max-width: 100%;
            margin: auto;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        &:first-of-type {
            height: 100%;
            -webkit-mask-image: url("../images/blob-shape.png");
            mask-image: url("../images/blob-shape.png");
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-position: center;
            mask-position: center;
            -webkit-mask-size: contain;
            mask-size: contain;

            img {
                height: 100%;
                -webkit-filter: drop-shadow(2px 4px 6px black);
                filter: drop-shadow(2px 4px 6px black);
            }

            &::before {
                content: "";
                position: absolute;
                z-index: 1;
                bottom: 0;
                height: 80%;
                width: 100%;
                background: linear-gradient(150deg, var(--main_color) 0%, var(--main_color) 100%);
                -webkit-mask-image: url("../images/blob-shape.png");
                mask-image: url("../images/blob-shape.png");
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                -webkit-mask-position: center;
                mask-position: center;
                -webkit-mask-size: contain;
                mask-size: contain;
            }
        }

        &:last-of-type {
            height: 50%;
            overflow: hidden;

            img {
                height: 200%;
            }
        }
    }
}


/*----------------btn-web-----------------*/

.btn-web {
    position: relative;
    font-size: 14px;
    color: var(--white_color);
    background: var(--main_color);
    font-weight: 500;
    height: 40px;
    transition: all .6s;
    min-width: 120px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: solid 1px var(--main_color);

    &:hover,
    &:focus {
        background: var(--white_color);
        color: var(--body_color);
        border-color: var(--white_color);
    }
}


/*----------------text-box-inline-----------------*/

.text-box-inline {
    position: relative;

    .subtitle {
        position: relative;
        text-transform: uppercase;
        text-transform: uppercase;
        font-weight: 300;
        letter-spacing: 3px;
    }

    h2 {
        font-size: 2rem;
        font-weight: 100;
        color: var(--white_color);
        text-transform: uppercase;
        span{
            text-transform: none;
        }
    }

    .btn-outline-light {
        height: 40px;
    }

    // react-tabs__tab-list
    .react-tabs__tab-list {
        border: none;
        margin: 0;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        height: 46px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        background-color: var(--body_color1);
        padding: 4px;
        border-radius: 30px;
        -webkit-box-shadow: var(--card-shadow);
        box-shadow: var(--card-shadow);

        .react-tabs__tab {
            position: relative;
            z-index: 1;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            min-width: 0;
            height: 100%;
            color: var(--white_color);
            font-weight: 200;
            text-transform: uppercase;
            white-space: nowrap;
            padding-left: 30px;
            padding-right: 30px;
            border-radius: 30px;
            cursor: pointer;
            -webkit-transition: color 0.9s cubic-bezier(0.4, 0, 0.2, 1);
            transition: color 0.9s cubic-bezier(0.4, 0, 0.2, 1);

            &.react-tabs__tab--selected {
                background-color: var(--main_color);
                border-color: var(--main_color);
                color: var(--white_color);
                transition: all .9s;
            }

            &:focus {
                &::after {
                    display: none;
                }
            }
        }
    }
}

@media(max-width:575px) {
    .text-box-inline {
        .react-tabs__tab-list {
            display: inherit;
            width: 100%;
            height: auto;
            border-radius: 10px;

            .react-tabs__tab {
                border-radius: 10px;
            }
        }
    }
}

// react-tabs__tab-panel
.react-tabs__tab-panel.react-tabs__tab-panel--selected {
    .skills-items {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 0 -8px -16px;
        -webkit-animation: fadeIn 0.3s ease-out;
        animation: fadeIn 0.3s ease-out;

        .items {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            position: relative;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            height: 80px;
            width: 80px;
            background-color: var(--body_color1);
            margin: 0 8px 16px;
            border-radius: 0.63rem;
            -webkit-box-shadow: var(--card-shadow);
            box-shadow: var(--card-shadow);

            &:hover,
            &:focus {
                .custom-tooltip {
                    opacity: 1;
                    visibility: visible;

                    &.custom-tooltip-top {
                        top: 15px;
                    }
                }
            }

            cursor: help;

            .skill-icon img {
                display: block;
                width: 48px;
                margin: auto;
            }

            // custom-tooltip
            .custom-tooltip {
                position: absolute;
                z-index: 1;
                min-width: -webkit-max-content;
                min-width: -moz-max-content;
                text-align: center;
                min-width: max-content;
                background-color: var(--main_color);
                color: var(--white_color);
                font-size: 0.8rem;
                left: 0;
                right: 0;
                top: 0;
                margin: 0 auto;
                // -webkit-transform: translateY(-50%);
                // transform: translateY(-50%);
                font-weight: 500;
                padding: 0.4rem 1rem;
                border-radius: 0.63rem;
                visibility: hidden;
                opacity: 0;
                pointer-events: none;
                -webkit-filter: var(--drop-shadow);
                filter: var(--drop-shadow);
                -webkit-transition: all 0.3s ease;
                transition: all 0.3s ease;

                &.custom-tooltip-top {
                    top: 0;
                    -webkit-transform: translate(0, -190%);
                    transform: translate(0, -190%);
                }

                &::after {
                    left: 40%;
                    transform: translate(100%, 100%);
                    transform: rotate(-88deg);
                    clip-path: polygon(0 50%, 100% 0, 100% 100%);
                    top: 91%;
                    height: 15px;
                    width: 10px;
                    content: "";
                    position: absolute;
                    background-color: var(--main_color);
                }
            }
        }
    }
}

// @media (min-width: 992px)
@media (min-width: 992px) {
    .text-box-inline {
        padding-left: 40px;

        .subtitle {
            position: absolute;
            top: 5px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            left: 0;
            -webkit-transform: rotate(270deg) translateX(-100%);
            transform: rotate(270deg) translateX(-100%);
            -webkit-transform-origin: left top;
            transform-origin: left top;

            &::before {
                content: "";
                display: inline-block;
                height: 2px;
                width: 60px;
                position: relative;
                left: -22px;
                background-color: var(--paragraph_color);
            }
        }
    }
}

@media (max-width: 991px) {
    .text-box-inline {
        .subtitle {
            &::before {
                display: none;
            }

            &::after {
                content: "";
                display: block;
                height: 2px;
                width: 60px;
                background-color: var(--paragraph_color);
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }
    }
}


/*----------------experience_ul-----------------*/

.experience_ul {
    position: relative;
    margin: 0;
    padding: 0;

    .owl-carousel {
        .owl-item {
            height: 22rem;
            padding-top: 68px;

            &.zxcxz {
                margin: 0;
            }
        }

        &.owl-theme {
            .owl-dots {
                .owl-dot {

                    &.active span,
                    &:hover span {
                        width: 20px;
                        background: var(--main_color);
                    }

                    span {
                        transition: all .6s;
                        border-radius: 100px;
                        margin: 5px;
                    }
                }
            }
        }
    }

    .experience_li {
        list-style: none;
        text-align: center;
        position: relative;
        border-radius: 0.63rem;
        background-color: var(--body_color1);
        padding: 50px 20px 50px 20px;
        transition: all .6s;
        box-shadow: 0px 0 14px -4px rgba(0, 0, 0, 0.7);

        &:hover,
        &:focus {
            box-shadow: 0px 0 14px -4px rgba(101, 171, 91, 0.7);
            background-color: var(--main_color);

            &.xpert_bg-color {
                background: #ed2024;
                box-shadow: 0px 0 14px -4px rgba(237, 32, 36, 0.7);

                .experience_p {
                    color: var(--white_color);
                }

                .experience_date {
                    color: #ed2024;
                }

                &:focus {
                    background: #ed2024;
                    box-shadow: 0px 0 14px -4px rgba(237, 32, 36, 0.7);
                }
            }

            &.eshopdesigner_bg-color {
                background: #3dade0;
                box-shadow: 0px 0 14px -4px rgba(61, 173, 224, 0.7);

                .experience_p {
                    color: var(--white_color);
                }

                .experience_date {
                    color: #3dade0;
                }

                &:focus {
                    background: #3dade0;
                    box-shadow: 0px 0 14px -4px rgba(61, 173, 224, 0.7);
                }
            }

            &.arhamsoft_bg-color {
                background: #f17700;
                box-shadow: 0px 0 14px -4px rgba(241, 119, 0, 0.7);

                .experience_p {
                    color: var(--white_color);
                }

                .experience_date {
                    color: #f17700;
                }

                &:focus {
                    background: #f17700;
                    box-shadow: 0px 0 14px -4px rgba(241, 119, 0, 0.7);
                }
            }

            &.face44_bg-color {
                background: #fccf45;
                box-shadow: 0px 0 14px -4px rgba(252, 207, 69, 0.7);

                .experience_p {
                    color: var(--white_color);
                }

                .experience_date {
                    color: #fccf45;
                }

                &:focus {
                    background: #fccf45;
                    box-shadow: 0px 0 14px -4px rgba(252, 207, 69, 0.7);
                }
            }

            &.tecmyer_bg-color {
                background: #764bc4;
                box-shadow: 0px 0 14px -4px rgba(118, 75, 196, 0.7);

                .experience_p {
                    color: var(--white_color);
                }

                .experience_date {
                    color: #764bc4;
                }

                &:focus {
                    background: #764bc4;
                    box-shadow: 0px 0 14px -4px rgba(118, 75, 196, 0.7);
                }
            }

            &.aliftech_bg-color {
                background: #f66d01;
                box-shadow: 0px 0 14px -4px rgba(246, 109, 1, 0.7);

                .experience_p {
                    color: var(--white_color);
                }

                .experience_date {
                    color: #f66d01;
                }

                &:focus {
                    background: #f66d01;
                    box-shadow: 0px 0 14px -4px rgba(246, 109, 1, 0.7);
                }
            }

            &.dtmhelderberg_bg-color {
                background: #d01818;
                box-shadow: 0px 0 14px -4px rgba(208, 24, 24, 0.7);

                .experience_p {
                    color: var(--white_color);
                }

                .experience_date {
                    color: #d01818;
                }

                &:focus {
                    background: #d01818;
                    box-shadow: 0px 0 14px -4px rgba(208, 24, 24, 0.7);
                }
            }

            &.exactprinto_bg-color {
                background: #3E4095;
                box-shadow: 0px 0 14px -4px rgba(62, 64, 149, 0.7);

                .experience_p {
                    color: var(--white_color);
                }

                .experience_date {
                    color: #3E4095;
                }

                &:focus {
                    background: #3E4095;
                    box-shadow: 0px 0 14px -4px rgba(62, 64, 149, 0.7);
                }
            }

            h2.experience_titleh2 {
                color: var(--white_color);
            }

            .experience_p {
                color: #255020;
            }

            .experience_date {
                border-color: var(--white_color);
                background: var(--white_color);
                color: var(--main_color);
            }

            .experience_avatar {
                background: var(--white_color);
            }
        }

        p {
            max-width: 85%;
        }

        .experience_avatar {
            position: absolute;
            left: 0;
            right: 0;
            border-radius: 100px;
            box-shadow: 0px 0 14px -4px rgba(0, 0, 0, 0.7);
            width: 80px;
            height: 80px;
            transition: all .6s;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            margin: 0 auto;
            top: -40px;
            background-color: var(--body_color1);

            img {
                width: 60px;
            }
        }

        h2.experience_titleh2 {
            font-size: 16px;
            transition: all 0.6s;
            font-weight: 200;
            text-transform: uppercase;
            margin: 0;
            color: var(--main_color);
        }

        h3.experience_titleh3 {
            margin: 0;
            transition: all .6s;
            font-size: 14px;
            font-weight: 500;
            color: var(--white_color);
        }

        .experience_date {
            position: absolute;
            bottom: -20px;
            left: 0;
            border: solid 2px transparent;
            right: 0;
            border-radius: 100px;
            width: fit-content;
            font-size: 16px;
            margin: 0 auto;
            padding: 5px 30px;
            font-weight: bold;
            color: var(--white_color);
            background-color: var(--body_color1);
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@media(min-width:1199px) {
    .experience_ul {
        .experience_li {
            max-width: 100%;
            margin: 0 auto;
        }
    }
}

/*----------------project__slidee-----------------*/
.project__slidee{
    &::before{
        content: '';
        position: absolute;
        height: 100%;
        width: 20%;
        left: auto;
        right: 0;
        border-radius: 70px 0 0 70px;
        background-color: var(--main_color);
    }
    // &::after{
    //     content: '';
    //     position: absolute;
    //     height: 100%;
    //     width: 100%;
    // }
}
@media(max-width:1199px) {
    .project__slidee{
        &::before{
            border-radius: 10px 0 0 10px;
        }
    }
}
/*----------------empty-state-----------------*/
.empty-state {
    margin: 40px auto;
    background: #242424;
    box-shadow: 1px 2px 10px #000000;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__content {
        padding: 48px;
        display: flex;
        align-items: center;
        flex-direction: column;

        .empty-state__icon {
            width: 200px;
            overflow: hidden;
            height: 200px;
            display: flex;
            align-items: center;
            border-radius: 200px;
            justify-content: center;
            background-color: #f7fafc;
            box-shadow: 0px 2px 1px #e1e3ec;

            img {
                width: 170px;
            }
        }

        .empty-state__message {
            color: #38a169;
            font-size: 1.5rem;
            font-weight: 500;
            margin-top: 0.85rem;
        }

        .empty-state__help {
            color: #a2a5b9;
            font-size: 0.875rem;
        }
    }
}

/*----------------workview_ul-----------------*/
.owl-carousel.workview_ul-owl .owl-stage-outer {

    // height: 400px;
    // overflow: unset;
    .owl-stage {
        height: 100%;

        .owl-item {
            height: 100%;
        }
    }
}

@media(max-width:575px) {
    .owl-carousel.workview_ul-owl .owl-stage-outer {
        height: auto;
    }
}

/*----------------workview_ul-----------------*/
.workview_ul .card {
    border: none;
    height: 100%;
    position: relative;
    // box-shadow: var(--card-shadow);
    background: transparent;

    .banner {
        position: relative;
        bottom: -25px;
        z-index: -1;
    }

    .card-body {
        background: var(--body_color1);
        border-radius: 5px 5px 10px 10px;

        p {
            line-height: 22px;
        }

        .title {
            font-size: 24px;
            color: var(--white_color);
            position: relative;
            padding-left: 15px;
            font-family: 'Rancho', cursive;

            &::after {
                position: absolute;
                content: '';
                border-radius: 10px;
                left: 0;
                height: 100%;
                width: 4px;
                background-color: var(--main_color);
            }
        }
    }

    .ul-btn {
        position: relative;
        bottom: 0;
        font-size: 16px;
        color: var(--white_color);
        left: 0;
        right: 0;
        max-width: 140px;
        margin: 15px auto 0 auto;
        background-color: var(--main_color);
        border-radius: 100px;
        padding: 5px 0;
        display: flex;
        border: none;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: var(--main_color);
        }
    }
}

@media(max-width:575px) {
    .workview_ul .card {
        .ul-btn {
            position: relative;
        }
    }
}

/*----------------portfolio-items-----------------*/
ul.portfolio-items {
    -webkit-column-gap: 25px;
    -moz-column-gap: 25px;
    column-gap: 25px;

    .portfolio-item {
        margin-bottom: 25px;
        border-radius: 0.0rem;
        overflow: hidden;
        position: relative;
        border: solid var(--white_color) 15px;
        height: 100%;

        &:hover {
            transform: perspective(800px) rotateY(-0deg) translateY(-0px) rotateX(0deg) scale(0.9);
            filter: blur(0);
            opacity: 1;

            .content {
                opacity: 1;
                bottom: 10px;

                h2 {
                    position: relative;
                    z-index: 2;
                }
            }

            .demo-overlay {
                opacity: 1;
            }

            &.foodzilla_border {
                border-color: var(--main_color);
            }

            &.fslegals_border {
                border-color: var(--fslegalscolor);
            }

            &.walupp_border {
                border-color: var(--waluppcolor);
            }

            &.arinstrumed_border {
                border-color: var(--arinstrumedcolor);
            }

            &.alphaauto_border {
                border-color: var(--alphaautocolor);
            }

            &.numero_border {
                border-color: var(--numerocolor);
            }

            &.alloywheels_border {
                border-color: var(--alloywheelscolor);
            }

            &.meditro_border {
                border-color: var(--meditrocolor);
            }

            &.smmProject_border {
                border-color: var(--smmprojectcolor);
            }

            &.bitvortex_border {
                border-color: var(--bitvortexcolor);
            }

            &.listandsell_border {
                border-color: var(--listandsellcolor);
            }

            &.empty_border {
                border-color: #1b1b1b;
            }
        }

        &.empty {
            background-image: url(../images/portfolio_empty_img.png);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            background-color: #222222;
            object-fit: cover;
            font-size: 36px;
            color: var(--white_color);
            font-weight: 500;
            text-transform: uppercase;

            .content {
                left: 0;
                margin: auto;
                top: 0;
                opacity: 1;
                bottom: 0;
                align-items: center;
                justify-content: center;
                display: flex;
                right: 0;

                h2 {
                    background-color: transparent;
                }
            }
        }

        .portfolio_img {
            img {
                background-size: cover;
                object-fit: cover;
                width: 100%;
            }
        }

        // 
        transform: perspective(1200px) rotateY(15deg) scale(0.9) rotateX(5deg);
        filter: blur(1px);
        opacity: 0.5;
        transition: 0.6s ease all;

        &:first-of-type {
            -webkit-column-break-inside: avoid;
            -moz-column-break-inside: avoid;
            break-inside: avoid;
            overflow: hidden;
        }

        .portfolio_img {
            &.overlay {
                &::before {
                    position: absolute;
                    content: '';
                    height: 100%;
                    transition: all .6s;
                    width: 100%;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    z-index: 1;
                }

                &.overlay_foodzilla {
                    &:hover {
                        &::before {
                            background-color: rgba(115, 201, 32, 0.3);
                        }
                    }
                }

                &.overlay_fslegals {
                    &:hover {
                        &::before {
                            background-color: rgba(222, 1, 1, 0.3);
                        }
                    }
                }

                &.overlay_walupp {
                    &:hover {
                        &::before {
                            background-color: rgba(255, 129, 137, 0.3);
                        }
                    }
                }

                &.overlay_arinstrumed {
                    &:hover {
                        &::before {
                            background-color: rgba(165, 205, 57, 0.3);
                        }
                    }
                }

                &.overlay_alphaauto {
                    &:hover {
                        &::before {
                            background-color: rgba(165, 205, 57, 0.3);
                        }
                    }
                }

                &.overlay_numero {
                    &:hover {
                        &::before {
                            background-color: rgba(194, 148, 86, 0.3);
                        }
                    }
                }

                &.overlay_alloywheels {
                    &:hover {
                        &::before {
                            background-color: rgba(227, 10, 21, 0.3);
                        }
                    }
                }

                &.overlay_meditro {
                    &:hover {
                        &::before {
                            background-color: rgba(254, 151, 14, 0.3);
                        }
                    }
                }

                &.overlay_smmProject {
                    &:hover {
                        &::before {
                            background-color: rgba(179, 60, 219, 0.3);
                        }
                    }
                }

                &.overlay_bitvortex {
                    &:hover {
                        &::before {
                            background-color: rgba(0, 196, 244, 0.3);
                        }
                    }
                }

                &.overlay_listandsell {
                    &:hover {
                        &::before {
                            background-color: rgba(21, 26, 123, 0.3);
                        }
                    }
                }
            }
        }

        .content {
            opacity: 0;
            position: absolute;
            bottom: -50px;
            left: 15px;
            transition: all .6s;

            h2 {
                font-size: 14px;
                letter-spacing: 1px;
                text-transform: uppercase;
                padding: 5px 10px;
                font-weight: 600;
                margin: 0;
                color: var(--white_color);
                background: var(--main_color);
                border-radius: 5px;

                small {
                    font-size: 12px;
                    font-weight: 500;
                    text-transform: capitalize;
                }
            }
        }
    }
}

@media(min-width: 1199px) {
    ul.portfolio-items {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
    }
}

@media(max-width: 1400px) {
    ul.portfolio-items {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;

        .portfolio-item {
            &:first-of-type {
                margin-bottom: 0;
            }
        }

        .nav-itemm {
            -webkit-column-count: 1;
            -moz-column-count: 1;
            column-count: 1;

            &:first-child {
                -webkit-column-span: all;
                -moz-column-span: all;
                column-span: all;
            }
        }
    }
}

@media(max-width: 991px) {
    ul.portfolio-items {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;

        .portfolio-item {
            height: 600px;
        }
    }
}

/*----------------back-to-top-----------------*/

.back-to-top {
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 44px;
    height: 44px;
    color: #fff;
    background: var(--main_color);
    text-align: center;
    line-height: 44px;
    z-index: 99;
    font-size: 20px;
    cursor: pointer;
    -webkit-box-shadow: 4px 3px 24px rgba(101, 177, 91, 1);
    box-shadow: 4px 3px 24px rgba(101, 177, 91, 1);
    display: none;
    -webkit-animation: backto-top-bounce 4s infinite ease-in-out;
    animation: backto-top-bounce 4s infinite ease-in-out;
    border-radius: 4px;

    .back-top {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-image: url(../images/arrow-down.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        transform: rotate(180deg);
    }
}

@-webkit-keyframes backto-top-bounce {
    0% {
        -webkit-transform: translateY(-4px);
        transform: translateY(-5px);
    }

    50% {
        -webkit-transform: translateY(8px);
        transform: translateY(10px);
    }

    100% {
        -webkit-transform: translateY(-4px);
        transform: translateY(-5px);
    }
}

@keyframes backto-top-bounce {
    0% {
        -webkit-transform: translateY(-4px);
        transform: translateY(-5px);
    }

    50% {
        -webkit-transform: translateY(8px);
        transform: translateY(10px);
    }

    100% {
        -webkit-transform: translateY(-4px);
        transform: translateY(-5px);
    }
}


/*-----------------------z-index----------------------*/

.animate-throb {
    -webkit-animation: throb 1s ease-in-out infinite alternate;
    animation: throb 1s ease-in-out infinite alternate
}

@-webkit-keyframes throb {
    0% {
        transform: scale(1)
    }

    to {
        transform: scale(1.05)
    }
}

@keyframes throb {
    0% {
        transform: scale(1)
    }

    to {
        transform: scale(1.05)
    }
}


/*-----------------------webkit-scrollbar----------------------*/

.sc5::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
    border-left: 0 solid transparent;
}

.sc5::-webkit-scrollbar-thumb {
    background-color: var(--main_color);
    border-radius: 0px;
}

.sc5::-webkit-scrollbar-thumb:hover {
    background-color: var(--main_color);
    opacity: 1;
}

@media (max-width: 991px) {
    .sc5::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
}


/*------------------------------------------------
## Size
------------------------------------------------*/

.fontweightregular {
    font-weight: 400 !important;
}

.fontweightmeduim {
    font-weight: 500 !important;
}

.fontweightbold {
    font-weight: 600 !important;
}

.fontweightbolder {
    font-weight: 700 !important;
}

.fontsize10 {
    font-size: 10px !important;
}

.fontsize12 {
    font-size: 12px !important;
}

.fontsize14 {
    font-size: 14px !important;
}

.fontsize16 {
    font-size: 16px !important;
}

.fontsize18 {
    font-size: 18px !important;
}

.fontsize20 {
    font-size: 20px !important;
}

.fontsize22 {
    font-size: 22px !important;
}

.fontsize24 {
    font-size: 24px !important;
}

.fontsize26 {
    font-size: 26px !important;
}

.fontsize28 {
    font-size: 28px !important;
}

.fontsize30 {
    font-size: 30px !important;
}

.fontsize32 {
    font-size: 32px !important;
}

.fontsize34 {
    font-size: 34px !important;
}

.fontsize36 {
    font-size: 36px !important;
}

.fontsize38 {
    font-size: 38px !important;
}

.fontsize40 {
    font-size: 40px !important;
}

.fontsize42 {
    font-size: 42px !important;
}

.fontsize44 {
    font-size: 44px !important;
}

.fontsize46 {
    font-size: 46px !important;
}

.fontsize48 {
    font-size: 48px !important;
}

.fontsize50 {
    font-size: 50px !important;
}

.fontsize52 {
    font-size: 52px !important;
}

.fontsize54 {
    font-size: 54px !important;
}

.fontsize56 {
    font-size: 56px !important;
}

.fontsize58 {
    font-size: 58px !important;
}

.fontsize60 {
    font-size: 60px !important;
}

.fontsize62 {
    font-size: 62px !important;
}




@font-face {
    font-family: 'Maison Neue Ext Demi';
    src: url('../fonts/MaisonNeueExt-Demi.woff2') format('woff2'),
        url('../fonts/MaisonNeueExt-Demi.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Ext Book';
    src: url('../fonts/MaisonNeueExt-Book.woff2') format('woff2'),
        url('../fonts/MaisonNeueExt-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Ext';
    src: url('../fonts/MaisonNeueExt-ExtraBold.woff2') format('woff2'),
        url('../fonts/MaisonNeueExt-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Ext';
    src: url('../fonts/MaisonNeueExt-Black.woff2') format('woff2'),
        url('../fonts/MaisonNeueExt-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Ext ExtraThin';
    src: url('../fonts/MaisonNeueExt-ExtraThin.woff2') format('woff2'),
        url('../fonts/MaisonNeueExt-ExtraThin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Ext';
    src: url('../fonts/MaisonNeueExt-Bold.woff2') format('woff2'),
        url('../fonts/MaisonNeueExt-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Ext';
    src: url('../fonts/MaisonNeueExt-Thin.woff2') format('woff2'),
        url('../fonts/MaisonNeueExt-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Book';
    src: url('../fonts/MaisonNeue-Book.woff2') format('woff2'),
        url('../fonts/MaisonNeue-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('../fonts/MaisonNeue-Light.woff2') format('woff2'),
        url('../fonts/MaisonNeue-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Ext Book';
    src: url('../fonts/MaisonNeueExt-BookItalic.woff2') format('woff2'),
        url('../fonts/MaisonNeueExt-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Ext';
    src: url('../fonts/MaisonNeueExt-Medium.woff2') format('woff2'),
        url('../fonts/MaisonNeueExt-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Ext Demi';
    src: url('../fonts/MaisonNeueExt-DemiItalic.woff2') format('woff2'),
        url('../fonts/MaisonNeueExt-DemiItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Ext';
    src: url('../fonts/MaisonNeueExt-BlackItalic.woff2') format('woff2'),
        url('../fonts/MaisonNeueExt-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Ext';
    src: url('../fonts/MaisonNeueExt-BoldItalic.woff2') format('woff2'),
        url('../fonts/MaisonNeueExt-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Ext';
    src: url('../fonts/MaisonNeueExt-Light.woff2') format('woff2'),
        url('../fonts/MaisonNeueExt-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Ext ExtraThin';
    src: url('../fonts/MaisonNeueExt-ExtraThinItalic.woff2') format('woff2'),
        url('../fonts/MaisonNeueExt-ExtraThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('../fonts/MaisonNeue-Mono.woff2') format('woff2'),
        url('../fonts/MaisonNeue-Mono.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Ext';
    src: url('../fonts/MaisonNeueExt-ThinItalic.woff2') format('woff2'),
        url('../fonts/MaisonNeueExt-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Ext';
    src: url('../fonts/MaisonNeueExt-LightItalic.woff2') format('woff2'),
        url('../fonts/MaisonNeueExt-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Ext';
    src: url('../fonts/MaisonNeueExt-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/MaisonNeueExt-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue Ext';
    src: url('../fonts/MaisonNeueExt-MediumItalic.woff2') format('woff2'),
        url('../fonts/MaisonNeueExt-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('../fonts/MaisonNeue-Bold.woff2') format('woff2'),
        url('../fonts/MaisonNeue-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
